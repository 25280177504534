<template>
  <div min-height="100" class="mt-10 shared-account-step-two">
    <p class="text-center mx-auto text-primary font-weight-bold">
      共有用アカウントを<br />作成しましょう
    </p>
    <div class="mt-16 mb-8">
      <p class="mb-2  font-16px fw-500">共有用アカウントとは</p>
      <div class="font-14px fw-400 text-87">
        <p class="mb-2">
          1つのアカウントを使用して、複数のメンバーが日報、現金出納、買掛情報の編集を行うことができます。
        </p>
        <p class="mb-2">
          共有アカウントでは、月報の閲覧や、各種設定などを行うことはできません。日々の数値の入力のみをしてほしいメンバーに使用してもらうのに便利です。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SharedAccountStepOne"
};
</script>
