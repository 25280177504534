<template>
  <v-container class="px-3">
    <v-stepper v-model="curr" class="transparent" elevation="0" color="green">
      <v-stepper-content
        class="px-4 pt-0 pb-5"
        v-for="(step, n) in steps"
        :step="n + 1"
        :key="n"
        :class="n !== 0 ? 'white' : ''"
      >
        <validation-observer :ref="'observer'">
          <v-form
            :ref="'stepForm'"
            v-model="step.valid"
            lazy-validation
            autocomplete="off"
          >
            <template v-if="step.template == '1'">
              <StepOne></StepOne>
            </template>

            <template v-if="step.template == '2'">
              <StepTwo :fields="step.fields"></StepTwo>
            </template>

            <template v-if="step.template == '3'">
              <StepThree
                @IncrementFieldCheck="addNewField()"
                :incrementField="true"
                :fields="step.fields"
              ></StepThree>
            </template>
          </v-form>
        </validation-observer>

        <v-btn
          block
          color="primary"
          class="stepper-btns mt-2"
          @click="register(n, 'true')"
          :loading="loading"
          :disabled="!step.valid"
        >
          {{ step.submitButtonText }}
        </v-btn>
        <v-btn
          block
          class="mt-3"
          color="stepper-btns grey white--text"
          @click="n == 0 ? goback() : (curr = n)"
          v-if="n != 1"
        >
          戻る
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import StepOne from "@/components/layout/shared-account/step-one";
import StepTwo from "@/components/layout/shared-account/step-two";
import StepThree from "@/components/layout/shared-account/step-three";
export default {
  name: "SharedAccountRegistration",
  components: { StepOne, StepTwo, StepThree },
  // metaInfo: {
  //   title: "共有用アカウント作成"
  // },
  props: ["shop"],
  data() {
    return {
      loading: false,
      errors: "",
      formStatus: false,
      curr: 1,
      lastStep: 3,
      steps: [
        {
          name: "Step 1",
          submitButtonText: "共有用アカウントを作成",
          template: "1",
          valid: true,
          fields: []
        },
        {
          name: "Step 2",
          submitButtonText: "次へ進む",
          template: "2",
          valid: true,
          fields: [
            {
              label: "ID",
              name: "s_account_id",
              type: "text",
              placeholder: "8文字以上の英数字を入力して下さい",
              value: "",
              rules: "required:ID|min:8|dont_allow_sysmbol|only_english_lang_allowed",
              post_label: "※「@」は使用できません。"
            },
            {
              label: "パスワード",
              name: "password",
              type: "text",
              placeholder: "8文字以上の英数字を入力して下さい",
              value: "",
              rules:
                "required:パスワード|verify_password|min:8|only_english_lang_allowed",
              post_label: "※ 英大文字、英小文字、数字をすべて使用してください"
            },
            {
              label: "パスワード(確認用)",
              name: "password_confirmation",
              type: "text",
              placeholder: "もう一度入力して下さい",
              value: "",
              rules: "required:パスワード(確認用)|password_confirmed:password"
            }
          ]
        },
        {
          name: "Step 3",
          submitButtonText: "登録完了",
          template: "3",
          valid: true,
          fields: [
            {
              label: "1.",
              name: "input_1",
              type: "text",
              placeholder: "メンバー１",
              value: "",
              rules: ""
            },
            {
              label: "2.",
              name: "input_2",
              type: "text",
              placeholder: "メンバー2",
              value: "",
              rules: ""
            },
            {
              label: "3.",
              name: "input_3",
              type: "text",
              placeholder: "メンバー3",
              value: "",
              rules: ""
            },
            {
              label: "4.",
              name: "input_4",
              type: "text",
              placeholder: "メンバー4",
              value: "",
              rules: ""
            },
            {
              label: "5.",
              name: "input_5",
              type: "text",
              placeholder: "メンバー5",
              value: "",
              rules: ""
            }
          ]
        }
      ],
      valid: false,
      stepForm: [],
      observer: []
    };
  },
  computed: {
    incrementStepFields() {
      return this.steps.find(i => i.template == "3").fields;
    },
    lastIncrementField() {
      return this.incrementStepFields[this.incrementStepFields.length - 1];
    },
    secondlastIncrementField() {
      return this.incrementStepFields[this.incrementStepFields.length - 2];
    }
  },
  methods: {
    addNewField() {
      let fieldsLength = this.incrementStepFields.length;

      if (this.lastIncrementField.value != "") {
        this.incrementStepFields.push({
          label: `${fieldsLength + 1}.`,
          name: `input_${fieldsLength + 1}`,
          type: "text",
          placeholder: `メンバー${fieldsLength + 1}`,
          value: "",
          rules: "required",
          newAdded: true
        });
      } else if (
        (!this.lastIncrementField.newAdded ||
          this.secondlastIncrementField.value == "") &&
        fieldsLength > 5
      ) {
        let lastIndex = this.incrementStepFields.indexOf(
          this.lastIncrementField
        );
        this.incrementStepFields.splice(lastIndex, 1);
      }
    },
    stepComplete(step) {
      return this.curr > step;
    },

    stepStatus(step) {
      return this.curr > parseInt(step) ? "green" : "blue";
    },

    moveToNextStep(n) {
      this.steps[n].valid = false;
      this.$refs.observer[n].validate().then(success => {
        this.steps[n].valid = success;
        if (success) {
          // continue to next
          this.curr = n + 2;
        }
      });
    },

    register(currentStep = "false", clicked = "false") {
      if (clicked == "true" && currentStep == 2) {
        this.registerAccount();
      } else {
        this.moveToNextStep(currentStep);
      }
    },
    registerAccount() {
      this.loading = true;
      let nameArray = [];
      let data = {};
      //data.shop_id = this.$store.getters.getFirstShopObject.id;
      data.shop_id = this.shop;
      this.steps.forEach(step => {
        step.fields.forEach(field => {
          if (field?.value) {
            if (step.template == "3") {
              nameArray.push(field.value);
            } else {
              data[field.name] = field.value;
            }
          }
        });
      });

      data.names = nameArray;
      this.$store
        .dispatch("SHOP_REGISTER_SHARED_ACCOUNT", data)
        .then(response => {
          this.$router.push({
            name: "StoreDetails",
            params: { id: this.shop }
          });
          this.$store.commit("showAlert", {
            text: "共有用アカウントを作成しました。",
            successStatus: "info"
          });
        })
        .catch(error => {
          this.steps.forEach((value, key) => {
            if (error?.data?.errors)
              this.$refs.observer[key].setErrors(error?.data?.errors);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goback() {
      let shop_id = this.$store.getters.getFirstShopObject.id;
      this.$router.push({ name: "StoreDetails", params: { id: shop_id } });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
<style>
.v-messages__message {
  line-height: 1.3 !important;
}
</style>
