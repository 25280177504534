<template>
  <div class="shared-account-step-two rounded-5 pt-9 pb-7">
    <p class="primary--text font-weight-bold">共有用アカウント作成</p>
    <!-- Step 1 -->
    <v-card-text class="pb-0" v-for="field in fields" :key="field.name">
      <v-row v-if="field.type == 'text'">
        <v-col cols="12" class="px-0" :md="field.additional_field ? '6' : '12'">
          <validation-provider
            v-slot="{ errors }"
            :rules="field.rules"
            :name="field.name"
          >
            <v-label>{{ field.label }} </v-label>
            <v-text-field
              :placeholder="field.placeholder"
              solo
              background-color="#EAEDF0"
              v-model="field.value"
              :error-messages="errors"
              :hide-details="errors.length <= 0"
              autocomplete="chrome-off"
            >
            </v-text-field>
            <span class="post-label" v-if="field.post_label">{{
              field.post_label
            }}</span>
            <div class="v-messages theme--light error--text">
              {{ errors ? errors[0] : "" }}
            </div>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: "SharedAccountStepTwo",
  props: {
    fields: {
      type: Array,
      default: () => {
        return [];
      },
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {}
};
</script>

<style lang="scss" scope>
.shared-account-step-two {
  .v-text-field__details {
    margin: 0;
  }
}
</style>
