<template>
  <div class="font-Roboto shared-account-step-three rounded-sm px-4 pt-9 pb-7">
    <p class="primary--text font-weight-bold mb-10">共有用アカウント作成</p>
    <p class="text-666 font-16px">メンバー名を入力してください</p>
    <!-- Step 1 -->
    <v-card-text class="pb-6 px-6 card-border rounded-5">
      <v-row v-for="field in fields" :key="field.name">
        <template v-if="field.type == 'text'">
          <v-col
            cols="12"
            class="pa-0"
            :md="field.additional_field ? '6' : '12'"
          >
            <validation-provider
              v-slot="{ errors }"
              :rules="field.rules"
              :name="field.name"
            >
              <div class="shared-inline-inputs position-relative">
                <label class="position-absolute font-16px fw-800 text-333"
                  >{{ field.label }}
                </label>
                <v-text-field
                  :placeholder="field.placeholder"
                  @keyup="checkLastInput()"
                  solo
                  background-color="#EAEDF0"
                  v-model="field.value"
                  :error-messages="errors"
                  :hide-details="errors.length <= 0"
                  autocomplete="chrome-off"
                  flat
                >
                </v-text-field>
              </div>
            </validation-provider>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: "SharedAccountStepThree",
  props: {
    fields: {
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
    incrementField: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    checkLastInput() {
      this.$emit("IncrementFieldCheck", `1`);
    },
  },
};
</script>

<style lang="scss" scope>
.shared-account-step-three {
  .v-text-field__details {
    margin: 0;
  }
  .card-border {
    border: 1px solid #bdbdbd;
  }
  .shared-inline-inputs {
    label {
      left: 7px;
      top: 7px;
      bottom: 0;
      margin: auto 0;
      height: 22px;
      z-index: 1;
    }
    .v-text-field {
      .v-input__control {
        min-height: 35px;
        > .v-input__slot {
          background-color: #fff !important;
          box-shadow: none !important;
          padding-top: 11px;
          padding-bottom: 4px;
          padding-left: 38px;
          padding-right: 12px;
          border-bottom: 1px dashed #bdbdbd;
          min-height: 25px;
          input {
            padding: 0;
            height: 20px;
          }
        }
      }
    }
  }
}
</style>
